import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'index.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { darkTheme, lightTheme } from './theme';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a1931a7e089243b3b6b99a0b6f361da3@o1058440.ingest.sentry.io/6046105',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const ThemedApp = () => {
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') === 'dark' ? darkTheme : lightTheme,
  );
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App setTheme={(themeNew) => {
        localStorage.setItem('theme', themeNew.palette.mode);
        setTheme(themeNew);
      }}
      />
    </ThemeProvider>
  );
};

ReactDOM.render(
  <ThemedApp />,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
