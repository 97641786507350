import { gql } from '@apollo/client';
import { TokenData } from '@buttonwood/sdk';

export interface TickData {
    id: string;
    liquidityGross: string;
    liquidityNet: string;
}

export interface PoolData {
    token0: TokenData;
    token1: TokenData;
    id: string;
    liquidity: string;
    sqrtPrice: string;
    tick: string;
    ticks: TickData[];
    feeTier: string;
    totalValueLockedToken0: string;
    totalValueLockedToken1: string;
}

export interface UniswapData {
    pools: PoolData[];
}

const getPoolsFields = `
    id
    liquidity
    feeTier
    sqrtPrice
    tick
    ticks {
        id
        liquidityGross
        liquidityNet
    }
    token0 {
        id
        symbol
        name
        decimals
        totalSupply
    }
    token1 {
        id
        symbol
        name
        decimals
        totalSupply
    }
    totalValueLockedToken0
    totalValueLockedToken1
`;

export const GET_POOLS = gql`
  query GetPools($tokens: [ID!]) {

      pools(where:{
          token0_in: $tokens
          token1_in: $tokens
          feeTier: 10000
      }) {
        ${getPoolsFields}
      }
  }
`;
