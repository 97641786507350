import { Shadows } from '@mui/material/styles/shadows';
import { createTheme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

interface BondPalette {
  backgroundUnselected: string;
  backgroundSelected: string[];
  contrastText: string;
  contrastTextHover: string;
}

declare module '@mui/material/styles/createPalette' {
  export interface TypeBackground {
    gradient: {
      top: string;
      bottom: string;
    };
  }

  export interface Palette {
    logo: string;
    chart: {
      background: string;
      lines: string;
      tooltip: string;
      series: string[];
      indicator: string;
      gridColor: string;
      slider: string;
      sliderActive: string;
      axisLabel: string;
    };
    bonds: BondPalette[];
  }

  export interface PaletteOptions {
    logo: string;
    chart: {
      background: string;
      lines: string;
      tooltip: string;
      series: string[];
      indicator: string;
      gridColor: string;
      slider: string;
      sliderActive: string;
      axisLabel: string;
    };
    bonds: BondPalette[];
  }
}

function getShadows(colors: string[]): Shadows {
  return [
    'none',
    `0px 2px 1px -1px ${colors[0]},0px 1px 1px 0px ${colors[1]},0px 1px 3px 0px ${colors[2]}`,
    `0px 3px 1px -2px ${colors[0]},0px 2px 2px 0px ${colors[1]},0px 1px 5px 0px ${colors[2]}`,
    `0px 3px 3px -2px ${colors[0]},0px 3px 4px 0px ${colors[1]},0px 1px 8px 0px ${colors[2]}`,
    `0px 2px 4px -1px ${colors[0]},0px 4px 5px 0px ${colors[1]},0px 1px 10px 0px ${colors[2]}`,
    `0px 3px 5px -1px ${colors[0]},0px 5px 8px 0px ${colors[1]},0px 1px 14px 0px ${colors[2]}`,
    `0px 3px 5px -1px ${colors[0]},0px 6px 10px 0px ${colors[1]},0px 1px 18px 0px ${colors[2]}`,
    `0px 4px 5px -2px ${colors[0]},0px 7px 10px 1px ${colors[1]},0px 2px 16px 1px ${colors[2]}`,
    `0px 5px 5px -3px ${colors[0]},0px 8px 10px 1px ${colors[1]},0px 3px 14px 2px ${colors[2]}`,
    `0px 5px 6px -3px ${colors[0]},0px 9px 12px 1px ${colors[1]},0px 3px 16px 2px ${colors[2]}`,
    `0px 6px 6px -3px ${colors[0]},0px 10px 14px 1px ${colors[1]},0px 4px 18px 3px ${colors[2]}`,
    `0px 6px 7px -4px ${colors[0]},0px 11px 15px 1px ${colors[1]},0px 4px 20px 3px ${colors[2]}`,
    `0px 7px 8px -4px ${colors[0]},0px 12px 17px 2px ${colors[1]},0px 5px 22px 4px ${colors[2]}`,
    `0px 7px 8px -4px ${colors[0]},0px 13px 19px 2px ${colors[1]},0px 5px 24px 4px ${colors[2]}`,
    `0px 7px 9px -4px ${colors[0]},0px 14px 21px 2px ${colors[1]},0px 5px 26px 4px ${colors[2]}`,
    `0px 8px 9px -5px ${colors[0]},0px 15px 22px 2px ${colors[1]},0px 6px 28px 5px ${colors[2]}`,
    `0px 8px 10px -5px ${colors[0]},0px 16px 24px 2px ${colors[1]},0px 6px 30px 5px ${colors[2]}`,
    `0px 8px 11px -5px ${colors[0]},0px 17px 26px 2px ${colors[1]},0px 6px 32px 5px ${colors[2]}`,
    `0px 9px 11px -5px ${colors[0]},0px 18px 28px 2px ${colors[1]},0px 7px 34px 6px ${colors[2]}`,
    `0px 9px 12px -6px ${colors[0]},0px 19px 29px 2px ${colors[1]},0px 7px 36px 6px ${colors[2]}`,
    `0px 10px 13px -6px ${colors[0]},0px 20px 31px 3px ${colors[1]},0px 8px 38px 7px ${colors[2]}`,
    `0px 10px 13px -6px ${colors[0]},0px 21px 33px 3px ${colors[1]},0px 8px 40px 7px ${colors[2]}`,
    `0px 10px 14px -6px ${colors[0]},0px 22px 35px 3px ${colors[1]},0px 8px 42px 7px ${colors[2]}`,
    `0px 11px 14px -7px ${colors[0]},0px 23px 36px 3px ${colors[1]},0px 9px 44px 8px ${colors[2]}`,
    `0px 11px 15px -7px ${colors[0]},0px 24px 38px 3px ${colors[1]},0px 9px 46px 8px ${colors[2]}`,
  ];
}

const typography: TypographyOptions = {
  htmlFontSize: 10,
  fontFamily: ['Raleway', 'HelveticaNeue', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  subtitle1: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
  },
  subtitle2: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  body1: {
    fontSize: '1.5rem',
  },
  button: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
};

// A custom theme for this app
export const lightTheme = createTheme({
  palette: {
    primary: {
      light: '#ffc6ad',
      main: '#ffb08f',
      dark: '#ff9163',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ffd5b3',
      main: '#fecc91',
      dark: '#ffb967',
      contrastText: '#ffffff',
    },
    mode: 'light',
    action: {
      disabled: 'rgba(255,255,255,0)',
    },
    background: {
      default: '#fafafa',
      paper: '#fff',
      gradient: {
        top: '#fffae4',
        bottom: '#ffffff',
      },
    },
    logo: '#ffffff',
    chart: {
      background: '#eaeaea',
      lines: '#ffffff',
      tooltip: '#fafafa',
      series: ['#8a97cb', '#9cabe5', '#adbeff'],
      indicator: '#f6941c',
      gridColor: '#ffc080',
      slider: '#000',
      sliderActive: '#ffb08f',
      axisLabel: '#000',
    },
    bonds: [
      {
        backgroundUnselected: '#ffffff',
        backgroundSelected: ['#69F0AE', '#B9F6CA'],
        contrastText: '#01592b',
        contrastTextHover: '#00964b',
      },
      {
        backgroundUnselected: '#ffffff',
        backgroundSelected: ['#B2FF59', '#B9F6CA'],
        contrastText: '#2e5600',
        contrastTextHover: '#529400',
      },
      {
        backgroundUnselected: '#ffffff',
        backgroundSelected: ['#EEFF41', '#F4FF81'],
        contrastText: '#4b5200',
        contrastTextHover: '#8e9a00',
      },
      {
        backgroundUnselected: '#ffffff',
        backgroundSelected: ['#FFFF00', '#FFFF8D'],
        contrastText: '#5e5400',
        contrastTextHover: '#9b8d00',
      },
      {
        backgroundUnselected: '#ffffff',
        backgroundSelected: ['#FFFF00', '#FFE57F'],
        contrastText: '#545400',
        contrastTextHover: '#a4a400',
      },
      {
        backgroundUnselected: '#ffffff',
        backgroundSelected: ['#FFD740', '#FFD180'],
        contrastText: '#5b4800',
        contrastTextHover: '#9d7f00',
      },
    ],
  },
  shadows: getShadows([
    'rgba(171,90,56,0.1)',
    'rgba(171,90,56,0.07)',
    'rgba(171,90,56,0.05)',
  ]),
  typography,
});

export const darkTheme = createTheme({
  palette: {
    primary: {
      light: '#678bc2',
      main: '#3f5f90',
      dark: '#213a60',
      contrastText: '#000000',
    },
    secondary: {
      light: '#9ac4e8',
      main: '#77a3c9',
      dark: '#517ca1',
      contrastText: '#000000',
    },
    mode: 'dark',
    text: {
      primary: '#d1f4fe',
      secondary: '#d1f4fe',
      disabled: 'rgba(209,244,254,0.5)',
    },
    action: {
      disabled: 'rgba(255,255,255,0)',
    },
    background: {
      default: '#0d0e12',
      paper: '#020202',
      gradient: {
        top: '#131b36',
        bottom: '#000000',
      },
    },
    logo: '#ffffff',
    chart: {
      background: '#1f2330',
      lines: '#020202',
      tooltip: '#fafafa',
      series: ['#8a97cb', '#9cabe5', '#adbeff'],
      indicator: '#f6941c',
      gridColor: '#ffc080',
      slider: '#d1f4fe',
      sliderActive: '#36507b',
      axisLabel: '#d1f4fe',
    },
    bonds: [
      {
        backgroundUnselected: '#020202',
        backgroundSelected: ['#01592b', '#00964b'],
        contrastText: '#69F0AE',
        contrastTextHover: '#B9F6CA',
      },
      {
        backgroundUnselected: '#020202',
        backgroundSelected: ['#2e5600', '#529400'],
        contrastText: '#B2FF59',
        contrastTextHover: '#B9F6CA',
      },
      {
        backgroundUnselected: '#020202',
        backgroundSelected: ['#4b5200', '#8e9a00'],
        contrastText: '#EEFF41',
        contrastTextHover: '#F4FF81',
      },
      {
        backgroundUnselected: '#020202',
        backgroundSelected: ['#5e5400', '#9b8d00'],
        contrastText: '#FFFF00',
        contrastTextHover: '#FFFF8D',
      },
      {
        backgroundUnselected: '#020202',
        backgroundSelected: ['#545400', '#a4a400'],
        contrastText: '#FFFF00',
        contrastTextHover: '#FFE57F',
      },
      {
        backgroundUnselected: '#020202',
        backgroundSelected: ['#5b4800', '#9d7f00'],
        contrastText: '#FFD740',
        contrastTextHover: '#FFD180',
      },
    ],
  },
  shadows: getShadows([
    'rgba(56,119,171,0.1)',
    'rgba(56,119,171,0.07)',
    'rgba(56,119,171,0.05)',
  ]),
  typography,
});
