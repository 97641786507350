import React from 'react';
import { ReactComponent as LogoEmblem } from 'assets/logo.svg';
import { ReactComponent as LogoText } from 'assets/logo-text.svg';
import { Box, styled } from '@mui/material';

const StyledLogoEmblem = styled(LogoEmblem, { name: 'StyledLogoEmblem' })(() => ({
  height: '100%',
  cursor: 'pointer',
}));

const StyledLogoText = styled(LogoText, { name: 'StyledLogoText' })(() => ({
  height: '58%',
  cursor: 'pointer',
}));

export interface LogoProps {
  /**
   * Color of LogoEmblem and LogoText
   */
  color: string;
  /**
   * Callback to fire when logo is clicked
   */
  onClick: () => void;
}

export function Logo({
  color,
  onClick,
}: LogoProps) {
  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
    >
      <StyledLogoEmblem
        onClick={onClick}
        color={color}
      />
      <StyledLogoText
        onClick={onClick}
        color={color}
      />
    </Box>
  );
}
