import React, { useContext, useEffect, useState } from 'react';
import { BigNumber } from 'ethers';
import BondContext from 'contexts/BondContext';
import { CurrencyAmount, Percent } from '@uniswap/sdk-core';
import { LoanManager } from '@buttonwood/sdk';
import { getDecimalNumber } from 'common-client';
import { Stats } from 'components/Stats';
import { PoolData } from 'queries/uniswap';

interface InterestRange {
  spotLendInterestMin: Percent | null;
  spotLendInterestMax: Percent | null;
}

function addressEquals(a: string, b: string): boolean {
  return a.toLowerCase() === b.toLowerCase();
}

async function getInterestRange(loanManagers: LoanManager[]): Promise<InterestRange> {
  const range:InterestRange = {
    spotLendInterestMin: null,
    spotLendInterestMax: null,
  };
  await Promise.all(loanManagers.map(async (loanManager) => {
    // Get spot price for 1 USDT deposit
    const depositAmount = CurrencyAmount.fromRawAmount(loanManager.currency,
      BigNumber.from(10).pow(loanManager.currency.decimals).toString());
    await Promise.all(loanManager.bond.tranches.slice(0, -1).map(
      async (tranche, trancheIndex) => {
        let interest = null;
        try {
          interest = await loanManager.getLenderInterest(depositAmount, trancheIndex);
          if (range.spotLendInterestMin === null
            || interest.lessThan(range.spotLendInterestMin)) {
            range.spotLendInterestMin = interest;
          }
          if (range.spotLendInterestMax === null
            || interest.greaterThan(range.spotLendInterestMax)) {
            range.spotLendInterestMax = interest;
          }
        } catch (err) {
          // swallow error, most likely caused by pool lacking liquidity
        }
      },
    ));
  }));
  return range;
}

function getTvl(loanManagers: LoanManager[], pools: PoolData[]) {
  let tvl = 0;
  loanManagers.forEach((loanManager) => {
    // Add bond collateral to TVL
    tvl += getDecimalNumber(
      loanManager.bond.totalCollateral, loanManager.bond.collateral.decimals,
    );
    // Add USDT liquidity for tranche tokens to TVL
    loanManager.pools.forEach((pool) => {
      const poolData = pools.find((data) => addressEquals(data.token0.id, pool.token0.address)
        && addressEquals(data.token1.id, pool.token1.address));
      if (poolData) {
        tvl += addressEquals(poolData.token0.id, loanManager.currency.address)
          ? parseFloat(poolData.totalValueLockedToken0)
          : parseFloat(poolData.totalValueLockedToken1);
      }
    });
  });
  return tvl;
}

export function StatsWrapper() {
  const { loanManagers, uniswapResponse } = useContext(BondContext);

  const [tvl, setTvl] = useState(0);
  const [interestRange, setInterestRange] = useState<InterestRange>(
    { spotLendInterestMin: null, spotLendInterestMax: null },
  );

  useEffect(() => {
    getInterestRange(loanManagers)
      .then((newInterestRange) => {
        setInterestRange(newInterestRange);
      });
  }, [loanManagers]);

  useEffect(() => {
    if (uniswapResponse?.data) {
      setTvl(getTvl(loanManagers, uniswapResponse.data.pools));
    } else {
      setTvl(0);
    }
  }, [loanManagers, uniswapResponse]);

  return (
    <Stats
      tvl={tvl}
      spotLendInterestMin={interestRange.spotLendInterestMin}
      spotLendInterestMax={interestRange.spotLendInterestMax}
    />
  );
}
