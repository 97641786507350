import React from 'react';
import {
  Box, BoxProps, styled, Typography, TypographyProps,
} from '@mui/material';
import { FormatCurrency, FormatPercentRange, InfoBubble } from 'common-client';
import { Percent } from '@uniswap/sdk-core';
import { BigNumber } from 'ethers';

const StatsBox = styled(Box, { name: 'StatsBox' })<BoxProps>(({ theme }) => ({
  borderRadius: 30,
  padding: 20,
  backgroundColor: theme.palette.background.paper,
  '& > *': {
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

const ContentBox = styled(Box, { name: 'ContentBox' })<BoxProps>(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: 20,
  padding: 20,
}));

const StatsTypography = styled(Typography, { name: 'StatsTypography' })<TypographyProps>(() => ({
  '& > *': {
    display: 'inline-block',
  },
}));

export interface StatsProps {
  /**
   * The current Total Value Locked in USD
   */
  tvl: number;
  /**
   * The lowest interest rate for lenders across all bonds when lending 1 USDT
   */
  spotLendInterestMin: Percent | null;
  /**
   The highest interest rate for lenders across all bonds when lending 1 USDT
   */
  spotLendInterestMax: Percent | null;
}

export function Stats({
  tvl,
  spotLendInterestMin,
  spotLendInterestMax,
}: StatsProps) {
  return (
    <StatsBox
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      data-testid="stats"
      boxShadow={8}
    >
      <ContentBox
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <StatsTypography variant="subtitle1">
          Spot interest rate
          <InfoBubble message="The minimum and maximum rates across all available bonds for deposits of 1 USDT" />
          :
          {' '}
          <FormatPercentRange valueMin={spotLendInterestMin} valueMax={spotLendInterestMax} />
        </StatsTypography>
        <StatsTypography variant="subtitle1">
          Total Value Locked
          <InfoBubble message="The sum value of collateral deposits and liquidity in bond markets" />
          :
          {' '}
          <FormatCurrency value={BigNumber.from(Math.round(tvl * 100))} decimals={2} />
        </StatsTypography>
      </ContentBox>
    </StatsBox>
  );
}
