import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { QueryResult, useQuery } from '@apollo/client';
import { Bond, LoanManager, TokenData } from '@buttonwood/sdk';
import {
  FeeAmount, Pool, Tick, TickListDataProvider,
} from '@uniswap/v3-sdk';
import { Token } from '@uniswap/sdk-core';
import { BondData, GET_BONDS_FILTERED } from 'queries/bond';
import { GET_POOLS, PoolData, UniswapData } from 'queries/uniswap';
import { Asset, ConfigContext } from 'config';
import { WalletConnectionContext, Web3Context } from 'common-client';
import SubgraphContext from 'contexts/SubgraphContext';

const BondContext = createContext<{
  loanManagers: LoanManager[],
  bondsResponse: QueryResult<BondData> | null,
  uniswapResponse: QueryResult<UniswapData> | null,
  loanCurrency: Token | null,
}>({
  loanManagers: [],
  bondsResponse: null,
  uniswapResponse: null,
  loanCurrency: null,
});

function addressEquals(a: string, b: string): boolean {
  return a.toLowerCase() === b.toLowerCase();
}

export function buildToken(data: TokenData, chainId: number): Token {
  return new Token(chainId, data.id, parseInt(data.decimals, 10), data.symbol, data.name);
}

/**
 * Create LoanManager instances from a list of pool data and the associated bonds
 * @param bonds List of all bonds to create loan managers for
 * @param pools Pool data from the uniswap subgraph
 * @param loanCurrency The address of the collateral token
 * @param chainId ETH network id
 */
export function getLoanManagers(
  bonds: Bond[],
  pools: PoolData[],
  loanCurrency: string | undefined,
  chainId: number,
): LoanManager[] {
  if (!loanCurrency) return [];
  const poolsByTranche = pools.reduce((acc: Map<string, Pool>, poolData: PoolData) => {
    const token0 = buildToken(poolData.token0, chainId);
    const token1 = buildToken(poolData.token1, chainId);
    // undefined if no swaps have occurred yet
    if (poolData.tick === null || poolData.tick === undefined) {
      return acc;
    }

    const ticks: Tick[] = poolData.ticks.map((t) => new Tick({ ...t, index: parseInt(t.id.split('#')[1], 10) }));
    ticks.sort((first, second) => first.index - second.index);
    const pool = new Pool(token0, token1, FeeAmount.HIGH,
      poolData.sqrtPrice, poolData.liquidity, parseInt(poolData.tick, 10),
      new TickListDataProvider(ticks, 200));

    if (addressEquals(poolData.token0.id, loanCurrency)) {
      acc.set(poolData.token1.id.toLowerCase(), pool);
    } else if (addressEquals(poolData.token1.id, loanCurrency)) {
      acc.set(poolData.token0.id.toLowerCase(), pool);
    } // else this pool is not relevant
    return acc;
  }, new Map());

  return bonds.reduce((acc: LoanManager[], bond: Bond) => {
    const orderedPools: Pool[] = [];
    // note we only need pools for a-y tranches
    bond.tranches.slice(0, -1).forEach((tranche) => {
      if (poolsByTranche.has(tranche.address.toLowerCase())) {
        orderedPools.push(poolsByTranche.get(tranche.address.toLowerCase())!);
      }
    });

    // skip any bonds that are missing tranches
    if (orderedPools.length === bond.tranches.length - 1) {
      acc.push(new LoanManager(bond, orderedPools));
    }

    return acc;
  }, []);
}

const BondProvider: React.FC = ({ children }) => {
  const [loanManagers, setLoanManagers] = useState<Array<LoanManager>>([]);
  const { provider } = useContext(Web3Context);
  const { chain } = useContext(WalletConnectionContext);
  const { networkConfig } = useContext(ConfigContext);
  const { tranche: trancheClient, uniswapV3: uniswapV3Client } = useContext(SubgraphContext);

  const loanCurrency: Token | null = networkConfig?.asset[Asset.USDT] || null;
  const collateralCurrency: Token | null = networkConfig?.asset[Asset.AMPL] || null;

  const bondsResponse: QueryResult<BondData> = useQuery<BondData>(
    GET_BONDS_FILTERED,
    {
      variables: { collateral: collateralCurrency?.address?.toLowerCase() },
      skip: !collateralCurrency?.address,
      client: trancheClient!!,
    },
  );

  // // ToDo: Show error pop-up if there's an error on bondsData
  const bonds: Bond[] = (bondsResponse.error || bondsResponse.loading || !bondsResponse.data)
    ? [] : bondsResponse.data.bonds.map((data) => new Bond(data, chain));

  const tokens: string[] = bonds.reduce((acc: string[], bond: Bond) => {
    bond.tranches.forEach((tranche) => acc.push(tranche.address.toLowerCase()));
    return acc;
  }, []);
  if (loanCurrency?.address) {
    tokens.push(loanCurrency.address.toLowerCase());
  }

  const uniswapResponse: QueryResult<UniswapData> = useQuery<UniswapData>(
    GET_POOLS,
    {
      variables: { tokens },
      client: uniswapV3Client!!,
      skip: !uniswapV3Client,
    },
  );

  useEffect(() => {
    if (!uniswapResponse.loading) {
      // ToDo: Refactor to use chain from configContext?
      const lm: LoanManager[] = (
        uniswapResponse.error || !uniswapResponse.data
      ) ? [] : getLoanManagers(
          bonds.filter((bond) => bond.totalDebt.gt(0)),
          uniswapResponse.data.pools,
          loanCurrency?.address?.toLowerCase(),
          chain,
        );

      setLoanManagers(lm);
    }
  }, [uniswapResponse.loading, provider]);

  return (
    <BondContext.Provider
      value={{
        loanManagers,
        bondsResponse,
        uniswapResponse,
        loanCurrency: loanCurrency || null,
      }}
    >
      {children}
    </BondContext.Provider>
  );
};

export { BondProvider };

export default BondContext;
