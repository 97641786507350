import React from 'react';
import {
  Box, BoxProps, styled, useTheme,
} from '@mui/material';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { LogoProps } from 'components/Logo';

const BackgroundBox = styled(Box, { name: 'BackgroundBox' })<BoxProps>(({ theme }) => {
  const { background } = theme.palette;
  const backgroundGradient = background.gradient;
  return ({
    zIndex: -1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: `linear-gradient(-180deg, ${
      backgroundGradient?.top || background.default
    } 0%, ${
      backgroundGradient?.bottom || background.default
    } 100%)`,
  });
});

const BackgroundLogo = styled(Logo, { name: 'BackgroundLogo' })<LogoProps>(() => ({
  position: 'absolute',
  transform: 'translate(-50%,-50%)',
  opacity: 0.05,
  height: '150vh',
  padding: '5px 0',
}));

// ToDo: @Fiddlekins make a story for this component
export function Background() {
  const theme = useTheme();
  return (
    <BackgroundBox
      position="fixed"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <div>
        <BackgroundLogo color={theme.palette.primary.dark} onClick={() => {}} />
      </div>
    </BackgroundBox>
  );
}
