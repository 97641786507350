import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Box, BoxProps, styled } from '@mui/material';

const ToggleSelectorBox = styled(Box, { name: 'ToggleSelectorBox' })<BoxProps>(({ theme }) => ({
  borderRadius: 20,
  padding: 5,
  backgroundColor: theme.palette.primary.main,
  width: 'fit-content',
  lineHeight: 1.6,
  fontFamily: theme.typography.fontFamily,
}));

const ToggleSelectorNavLink = styled(NavLink, { name: 'ToggleSelectorNavLink' })<NavLinkProps>(({ theme }) => ({
  display: 'inline-flex',
  flexGrow: 1,
  borderRadius: 14,
  alignItems: 'center',
  height: 38,
  paddingLeft: 15,
  paddingRight: 15,
  fontWeight: 600,
  fontSize: 11,
  color: theme.palette.text.disabled,
  textDecoration: 'none',
  textTransform: 'uppercase',
  textAlign: 'center',
  '&:hover': {
    color: theme.palette.primary.contrastText,
  },
  '&.active': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}));

export interface ToggleSelectorProps {
  /**
   * What the options in the selector are
   */
  optionsList: Array<[string, string]>;
  /**
   * style
   */
  style?: React.CSSProperties;
}

export function ToggleSelector({
  optionsList = [['Borrow', 'borrow'], ['My Bonds', 'Bonds']],
  style = {},
}: ToggleSelectorProps) {
  return (
    <ToggleSelectorBox
      flexGrow={1}
      flexDirection="row"
      alignItems="stretch"
      justifyContent="space-between"
      flexWrap="nowrap"
      style={style}
    >
      {
        optionsList.map((option) => (
          <ToggleSelectorNavLink
            key={option[1]}
            to={`/${option[1]}`}
          >
            {option[0]}
          </ToggleSelectorNavLink>
        ))
      }
    </ToggleSelectorBox>
  );
}
