import { Token } from '@uniswap/sdk-core';
import { Chain, createConfigs, SubgraphConfig } from 'common-client';
import ethereumLogoUrl from 'common-client/dist/assets/images/ethereum-logo.png';

const SUBGRAPH_BASE_URI = 'https://api.thegraph.com/subgraphs/name';

export const SUPPORTED_CHAINS = [Chain.Mainnet, Chain.Kovan];

export enum Asset {
    AMPL,
    USDT,
}

export type ChainInfo = {
  explorer: string;
  label: string;
  logoUrl: string;
}

export type NetworkConfig = {
  network: {
    name: string;
    chainId: number;
    networkId: number;
  };
  asset: Record<Asset, Token>;
  loanRouterAddress: string;
  swapRouterAddress: string;
  chainInfo: ChainInfo;
}

export type Config = Partial<Record<Chain, NetworkConfig>>;

const config: Config = {
  [Chain.Mainnet]: {
    network: {
      name: 'mainnet',
      chainId: 1,
      networkId: 1,
    },
    asset: {
      [Asset.USDT]: new Token(1, '0xdac17f958d2ee523a2206206994597c13d831ec7', 6, 'USDT', 'Tether'),
      [Asset.AMPL]: new Token(1, '0xd46ba6d942050d489dbd938a2c909a5d5039a161', 9, 'AMPL', 'Ampleforth'),
    },
    loanRouterAddress: '0x26FA9931Ac24B2AbDc2e2de23Ae722EdE5915464',
    swapRouterAddress: '0xe592427a0aece92de3edee1f18e0157c05861564',
    chainInfo: {
      explorer: 'https://etherscan.io/',
      label: 'Ethereum',
      logoUrl: ethereumLogoUrl,
    },
  },
  [Chain.Kovan]: {
    network: {
      name: 'kovan',
      chainId: 42,
      networkId: 42,
    },
    asset: {
      [Asset.USDT]: new Token(42, '0xaff4481d10270f50f203e0763e2597776068cbc5', 18, 'USDT', 'Tether'),
      [Asset.AMPL]: new Token(42, '0x3e0437898a5667a4769b1ca5a34aab1ae7e81377', 9, 'AMPL', 'Ampleforth'),
    },
    loanRouterAddress: '0x063984f18515f5564a71bf12764C9a5bBBe3A26E',
    swapRouterAddress: '0xe592427a0aece92de3edee1f18e0157c05861564',
    chainInfo: {
      explorer: 'https://kovan.etherscan.io/',
      label: 'Kovan',
      logoUrl: ethereumLogoUrl,
    },
  },
};

export const SUBGRAPH_CONFIG: SubgraphConfig = {
  [Chain.Mainnet.valueOf()]: {
    tranche: `${SUBGRAPH_BASE_URI}/buttonwood-protocol/tranche`,
    uniswapV3: `${SUBGRAPH_BASE_URI}/uniswap/uniswap-v3`,
  },
  [Chain.Kovan.valueOf()]: {
    tranche: `${SUBGRAPH_BASE_URI}/marktoda/buttonwoodkovan`,
    uniswapV3: `${SUBGRAPH_BASE_URI}/marshallteach007/uniswapv3kovan`,
  },
};

interface MenuLines {
  name: string;
  url: string;
}

export const menuConfig: MenuLines[] = [
  {
    name: 'Twitter',
    url: 'https://twitter.com/ButtonDeFi',
  },
  {
    name: 'Discord',
    url: 'https://discord.gg/P8gFKUYtfG',
  },
  {
    name: 'Website',
    url: 'https://button.foundation/',
  },
  {
    name: 'Docs',
    url: 'https://docs.prl.one',
  },
  {
    name: 'Analytics',
    url: 'https://dune.xyz/prl/Mooncake-Markets',
  },
  {
    name: 'About',
    url: 'https://mooncake.prl.one',
  },
];

function getConfig(chain: Chain): NetworkConfig | null {
  if (chain === Chain.Unsupported) {
    return null;
  }
  return config[chain] || null;
}

const { ConfigContext, ConfigProvider } = createConfigs(getConfig);
export { ConfigContext, ConfigProvider };
