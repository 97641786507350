import React, { useContext } from 'react';
import { TopBar, Web3Context } from 'common-client';
import { ConfigContext } from 'config';
import { LogoWrapper } from 'wrappers/LogoWrapper';
import { darkTheme, lightTheme } from 'theme';
import { Theme, useTheme } from '@mui/material';

export interface TopBarWrapperProps {
  /**
   * Menu Button Options
   */
  options: Array<string>;
  /**
   * Menu Button Links
   */
  links: Array<string>;
  /**
   * Callback to set the theme the app uses
   */
  setTheme: (theme: Theme) => void;
}

export function TopBarWrapper({
  options, links, setTheme,
}: TopBarWrapperProps) {
  const theme = useTheme();

  const { selectWallet, disconnectWallet, address } = useContext(Web3Context);
  const { networkConfig } = useContext(ConfigContext);

  return (
    <TopBar
      options={options}
      links={links}
      toggleTheme={() => setTheme(theme.palette.mode === 'light' ? darkTheme : lightTheme)}
      selectWallet={selectWallet}
      disconnectWallet={disconnectWallet}
      address={address}
      logo={<LogoWrapper color={theme.palette.logo} />}
      chainLogoUrl={networkConfig?.chainInfo?.logoUrl}
      chainLabel={networkConfig?.chainInfo?.label || 'Unknown network'}
    />
  );
}
