import { gql } from '@apollo/client';
import { BondData as BondGraphqlResult } from '@buttonwood/sdk';

export interface BondData {
  bonds: BondGraphqlResult[];
}

const getBondsFields = `
      id
      owners
      collateral {
        id
        symbol
        name
        decimals
        totalSupply
      }
      maturityDate
      isMature
      totalDebt
      totalCollateral
      depositLimit
      tranches {
        id
        index
        token {
          id
          decimals
          totalSupply
        }
        ratio
        totalCollateral
      }
`;

export const GET_BONDS = gql`
  query getBonds {
    bonds {
      ${getBondsFields}
    }
  }
`;

export const GET_BONDS_FILTERED = gql`
  query getBonds($collateral: ID!) {
      bonds(where: { collateral: $collateral }) {
        ${getBondsFields}
      }
  }
`;

export const GET_BONDS_BY_MATURITY_DATE = gql`
  query getBonds($maturityDate: String!) {
      bonds(where: { maturityDate_lte: $maturityDate }) {
        ${getBondsFields}
      }
  }
`;
