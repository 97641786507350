import React, { lazy, Suspense, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import { withProfiler } from '@sentry/react';
import {
  Ellipsis, TokenListProvider, WalletConnectionProvider, Web3Provider, ToastProvider,
} from 'common-client';
import { TopBarWrapper } from 'wrappers/TopBarWrapper';
import { ToggleSelector } from 'components/ToggleSelector';
import { ConfigProvider, menuConfig, SUPPORTED_CHAINS } from 'config';
import { BondProvider } from 'contexts/BondContext';
import { SubgraphProvider } from 'contexts/SubgraphContext';
import { Background } from 'components/Background';
import { LogoWrapper } from 'wrappers/LogoWrapper';
import {
  Box, BoxProps, styled, Theme, useTheme,
} from '@mui/material';
import { StatsWrapper } from './wrappers/StatsWrapper';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ethereum: any;
  }
}

window.ethereum = window.ethereum || {};

const AppDiv = styled('div', { name: 'AppDiv' })(() => ({
  minHeight: '100vh',
  WebkitTapHighlightColor: 'transparent',
}));

const BodyBox = styled(Box, { name: 'BodyBox' })<BoxProps>(() => ({
  padding: '0 20px 20px 20px',
}));

const LogoDiv = styled('div', { name: 'LogoDiv' })(({ theme }) => ({
  height: '60px',
  marginTop: '20px',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const ContentBox = styled(Box, { name: 'ContentBox' })<BoxProps>(({ theme }) => ({
  marginTop: '20px',
  [theme.breakpoints.down('sm')]: {
    minWidth: 0,
    maxWidth: '335px',
  },
  '& > *': {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

export interface AppProps {
  /**
   * Callback to set the theme the app uses
   */
  setTheme: (theme: Theme) => void;
}

const DynamicLoader = ({ component }: { component: string }) => {
  const LazyComponent = useMemo(() => lazy(() => import(`routes/${component}`)), [component]);
  return (
    <Suspense fallback={<Ellipsis />}>
      <LazyComponent />
    </Suspense>
  );
};

function App({ setTheme }: AppProps) {
  const theme = useTheme();

  // ToDo: @socksNFlops fix the onClick callback in Logo
  return (
    <IntlProvider locale={navigator.language}>
      <Router>
        <Web3Provider>
          <WalletConnectionProvider supportedChains={SUPPORTED_CHAINS}>
            <TokenListProvider>
              <ConfigProvider>
                <SubgraphProvider>
                  <BondProvider>
                    <ToastProvider>
                      <AppDiv>
                        <Background />
                        <TopBarWrapper
                          options={menuConfig.map((m) => m.name)}
                          links={menuConfig.map((m) => m.url)}
                          setTheme={setTheme}
                        />
                        <BodyBox
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <LogoDiv>
                            <LogoWrapper color={theme.palette.primary.dark} />
                          </LogoDiv>
                          <ToggleSelector
                            optionsList={[['Borrow', 'borrow'], ['Lend', 'lend'], ['My Bonds', 'bonds'], ['LP Tool', 'liquidity']]}
                            style={{ marginTop: 20 }}
                          />
                          <ContentBox
                            display="flex"
                            flexDirection="column"
                            alignItems="stretch"
                          >
                            <Switch>
                              <Route path="/borrow">
                                <DynamicLoader component="Borrow" />
                              </Route>
                              <Route path="/bonds">
                                <DynamicLoader component="Bonds" />
                              </Route>
                              <Route path="/lend">
                                <DynamicLoader component="Lend" />
                              </Route>
                              <Route path="/liquidity">
                                <DynamicLoader component="Liquidity" />
                              </Route>
                              <Route path="/">
                                <Redirect to="/borrow" />
                              </Route>
                            </Switch>
                            <StatsWrapper />
                          </ContentBox>
                        </BodyBox>
                      </AppDiv>
                    </ToastProvider>
                  </BondProvider>
                </SubgraphProvider>
              </ConfigProvider>
            </TokenListProvider>
          </WalletConnectionProvider>
        </Web3Provider>
      </Router>
    </IntlProvider>
  );
}

export default withProfiler(App);
